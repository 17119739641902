const testimonials = [
  {
    client: "Singapore Golf Association",
    logo: "singapore-golf-association.png",
    text:
      "We have worked with S B Tan Audit PAC for the past few years and they have been professional with their work. They were able to meet the deadline of the yearly audit and organised in their queries. The team at S B Tan Audit PAC has been helpful, knowledgeable and responsive",
  },
  {
    client: "Aidha",
    logo: "aidha.jpg",
    text:
      "We have been clients of SB Tan Audit PAC for a few years now and have built up a positive and respectful partnership with them. The team at SB Tan has been professional, constructive and always helpful in providing guidance to our non-profit organization. We appreciate the audit team’s technical expertise and responsiveness, making the audit process a smooth and efficient one.",
  },
  {
    client: "Singapore Ice Skating Association",
    logo: "singapore-ice-skating-association.jpg",
    text:
      "SISA has always been very pleased with your good company's service, professionalism and punctuality. Despite being a long-time time client, your team has always maintained the required professionalism and precision in their job as an auditor.",
  },

  {
    client: "Wrestling Federation of Singapore",
    logo: "wrestling-federation-of-singapore.jpg",
    text:
      "They come highly recommended so we had high expectations and they did not disappoint. We have nothing but the best experience with SB Tan & Co. They are very thorough, precise and methodical, yet very fast and efficient which could only come with lots of experience and expertise. They are very responsive and knowledgeable so any queries or issues we had encountered were very promptly settled. They will also go the extra mile and provide us extra services like necessary complicated adjustments without additional costs.",
  },
]
export default testimonials
