import React, { useEffect } from "react"
import AOS from "aos"

import "aos/dist/aos.css"
import * as styles from "../styles/index.module.scss"
import {
  CustomButton,
  Layout,
  Section,
  TextContainer,
  Card,
  Image,
  CustomCarousel,
} from "../components"

import testimonials from "../data/testimonials"
import { Helmet } from "react-helmet"

const HomePage = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    })
  }, [])
  return (
    <div style={{ "overflow-x": "hidden" }}>
      <Section className={styles.heroSection}>
        <div className={styles.heroTextContainer}>
          <h1 className={styles.heroTitle}>Keeping your business healthy</h1>
          <h3 className={styles.heroSubtitle}>
            Our team of qualifed and experienced accountants is happy to help.
            Let us know how we can best support your business today.
          </h3>
          <CustomButton to="/contact" className={styles.heroButton}>
            Contact us
          </CustomButton>
        </div>
      </Section>
      <Section
        className={styles.aboutSection}
        data-aos="fade-up"
        align="center"
      >
        <TextContainer
          title="Accountants you can trust"
          text="S B Tan has been providing accounting & GST services to companies in
          services and trading on monthly, quarterly or yearly basis. Our
          accountants are familiar with the requirements for corporate tax
          returns as set by local tax authorities (IRAS)."
        >
          <CustomButton>Find out more</CustomButton>
        </TextContainer>
        <div className={styles.aboutImage}>
          <Image src="about.jpg" alt="company-photo"></Image>
        </div>
      </Section>
      <Section
        className={styles.servicesSection}
        curve={true}
        data-aos="fade-right"
        align="left"
      >
        <TextContainer
          title="Services you can depend on"
          text="From tax preparation to auditing services, S B Tan can cover your
            needs. We have had experience auditing various companies in
            services, trading, manufacturing, shipping and freight forwarding,
            MCSTs etc."
        >
          <CustomButton>Find out more</CustomButton>
        </TextContainer>
        <div className={styles.servicesImage}>
          <Image src="services.svg" alt="services logo"></Image>
        </div>
      </Section>

      <Section
        className={styles.sageSection}
        data-aos="zoom-in-up"
        align="center"
      >
        <Card
          img={{ src: "product-1.jpg", alt: "sage50-pro" }}
          title="Sage 50 Premium Account"
          text="from S$690"
        ></Card>
        <Card
          img={{ src: "product-2.jpg", alt: "sage50-premium" }}
          title="Sage 50 Premium Account"
          text="from S$930"
        ></Card>
        <Card
          img={{ src: "product-3.jpg", alt: "sage50-quantum" }}
          title="Sage 50 Premium Account"
          text="from S$990/user"
        ></Card>
      </Section>
      <Section
        className={styles.xeroSection}
        data-aos="fade-left"
        align="right"
      >
        <div className={styles.xeroImage}>
          <Image src="xero.png" alt="xero"></Image>
        </div>
        <TextContainer
          title="S B Tan is now a Xero Partner!"
          text="Here at S B Tan, we believe technology can help improve our
          processes and keep things simple. We offer comprehensive support,
          training and set-up for the products we offer."
        >
          <CustomButton>Find out more</CustomButton>
        </TextContainer>
      </Section>
      <Section
        className={styles.testimonialSection}
        data-aos="zoom-in"
        align="center"
      >
        <TextContainer
          title="Why we do what we do"
          text="We are happy to help businesses grow and listen to feedback on how
          we can improve our services. Read what some of our clients have
          said."
        ></TextContainer>
        <div className={styles.testimonialCarousel}>
          <CustomCarousel pages={testimonials}></CustomCarousel>
        </div>
      </Section>
    </div>
  )
}

export default function Home() {
  return (
    <div>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Layout theme="light">
        <HomePage></HomePage>
      </Layout>
    </div>
  )
}
