// extracted by mini-css-extract-plugin
export var heroSection = "index-module--heroSection--1IAlh";
export var heroTextContainer = "index-module--heroTextContainer--1DaAg";
export var heroTitle = "index-module--heroTitle--2xgaR";
export var heroSubtitle = "index-module--heroSubtitle--1BXHt";
export var heroButton = "index-module--heroButton--1FwAp";
export var aboutSection = "index-module--aboutSection--2EtkN";
export var aboutImage = "index-module--aboutImage--1i76n";
export var servicesSection = "index-module--servicesSection--YsaoH";
export var servicesImage = "index-module--servicesImage--1jGb6";
export var xeroSection = "index-module--xeroSection--31lqm";
export var xeroImage = "index-module--xeroImage--3ZW9y";
export var sageSection = "index-module--sageSection--ETyQM";
export var testimonialSection = "index-module--testimonialSection--2Drtb";
export var testimonialCarousel = "index-module--testimonialCarousel--3piku";